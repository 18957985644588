import { NavLink } from "react-router-dom";
import { defaultImage, fullUrl } from "../../services/apiService";
import { formatPrice } from "../../MesPages/Panier/Cart";
import { verifyImageLink } from "../../services/lib";

const ProductWrap = ({ product, userData = {}, col_css, onSuccess }) => {
    const handleClick = () => {
        // Enregistrer l'ID du produit dans localStorage
        localStorage.setItem("selectedProductId", product.ArtID);
    };

    return (
        <div className={` ${col_css}`}>
            <div
                className="product-wrap product-wrap-max-w flex-shrink-0"
                style={{ width: "255px" }}
            >
                <div className="product text-center">
                    <figure className="product-media">
                        <NavLink to={"/detail-produit"} onClick={handleClick}>
                            <img
                                src={`${
                                    product.ArtGPicID
                                        ? verifyImageLink(
                                              fullUrl + product.ArtGPicID
                                          ) !== false
                                            ? fullUrl + product.ArtGPicID
                                            : fullUrl + defaultImage
                                        : fullUrl + defaultImage
                                }`}
                                alt={product.ArtLib}
                                width={300}
                                height={338}
                            />
                        </NavLink>
                    </figure>
                    <NavLink to={"/detail-produit"} onClick={handleClick}>
                        <div className="product-details">
                            <div className="product-cat">
                                {product?.ArtCateg}
                            </div>
                            <h2 className="product-name">{product.ArtLib}</h2>
                            {userData && userData.STR_UTITOKEN && (
                                <div className="product-pa-wrapper">
                                    <div className="product-price">
                                        {formatPrice(
                                            parseInt(product?.ArtPrixBase)
                                        )}{" "}
                                        FCFA
                                    </div>
                                </div>
                            )}
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ProductWrap;
