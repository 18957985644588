// src/components/Accueil.js
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { doDisConnexion } from "../services/apiService";

const MobileMenu = ({ megaMenu }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log(user)
    const handleLogout = async () => {
        try {
            const payload = new URLSearchParams();
            payload.append("mode", "doDisConnexion");
            payload.append("STR_UTITOKEN", user?.STR_UTITOKEN);

            // Attendre la réponse de la fonction de déconnexion
            const response = await doDisConnexion(payload);
            const userData = response.data;

            if (userData.code_statut === "1") {
                if (localStorage.getItem("userData")) {
                    localStorage.removeItem("userData");
                    window.location.replace("/");
                }

                // Actualise la page
            } else {
                toast.error(userData.desc_statut);
            }
        } catch (error) {
            console.error("Erreur de déconnexion:", error);
        }
    };

    return (
        <>
            {/* Start of Mobile Menu */}
            <div
                className="mobile-menu-wrapper"
                style={{ background: "#214293;" }}
            >
                <div className="mobile-menu-overlay" />
                {/* End of .mobile-menu-overlay */}
                <Link to="#" className="mobile-menu-close">
                    <i className="close-icon" />
                </Link>
                {/* End of .mobile-menu-close */}
                <div className="mobile-menu-container scrollable">
                    <div className="tab-content">
                        <div className="tab-pane active" id="main-menu">
                            <ul className="mobile-menu">
                                <li>
                                    <Link to="/">Accueil</Link>
                                </li>
                                <li>
                                    <Link to="/shop">Nos Produits</Link>
                                    <ul>
                                        {megaMenu &&
                                            megaMenu.map((item, index) => (
                                                <li key={index}>
                                                    <Link to="/shop">
                                                        {item.title}
                                                    </Link>
                                                    <ul>
                                                        {item.items.map(
                                                            (
                                                                subItem,
                                                                subIndex
                                                            ) => (
                                                                <li>
                                                                    <Link
                                                                        to={
                                                                            subItem.href
                                                                        }
                                                                        key={
                                                                            subIndex
                                                                        }
                                                                    >
                                                                        {
                                                                            subItem.label
                                                                        }
                                                                    </Link>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Contacts</Link>
                                </li>
                                {user && (
                                    <>
                                        <li>
                                            <Link to="">Tableau de bord</Link>
                                            <ul>
                                                <li>
                                                    <Link to="/livraison">
                                                        Calendrier de livraison
                                                    </Link>
                                                    <Link to="/mes-commandes">
                                                        Mes commandes
                                                    </Link>
                                                    <Link to="/factures">
                                                        Mes factures
                                                    </Link>
                                                    <Link to="/mes-commandes">
                                                        Mes règlements
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        {/* <li> */}
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    handleLogout();
                                                }}
                                                style={{
                                                    color: "inherit",
                                                    background: "inherit",
                                                    border: "inherit",
                                                    fontFamily:
                                                        "Poppins, sans-serif",
                                                    padding:
                                                        "1.3rem 0.6rem 1.3rem 1rem",
                                                }}
                                            >
                                                Deconnexion
                                            </button>
                                        {/* </li> */}
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of Mobile Menu */}
        </>
    );
};

export default MobileMenu;
